import styled from 'styled-components';
import React from 'react';
import {PageContent} from '../components/Layout';
import {NavigationContext} from '../services/navigation';
import usePressData from '../queries/usePressData';
import {useLocation} from '@reach/router';
import {PressItemsContainer, Appear} from '../components/atoms';
import PressItem from '../components/PressItem';
import {Helmet} from "react-helmet"
import {useStaticQuery, graphql} from 'gatsby';

// Page with press related items about Stavros Markonis, like interviews and announcements

const Container = styled(PageContent)`
  display: flex;
`;

const byDateDesc = (a: any, b: any) => Number(new Date(b.entry.date)) - Number(new Date(a.entry.date));

const Press: React.FC = () => {
  const {setLocation} = React.useContext(NavigationContext);
  React.useEffect(() => {
    setLocation(window.location.pathname)
  }, [])
  const {bisbikis} = useStaticQuery(graphql`
  query {
    bisbikis: file(relativePath: {eq: "mesaia4.jpg"}) {
      publicURL
    }
  }
`)
  const {pressEntries, seo} = usePressData();
  const {state} = useLocation();
  const ref = React.useRef();
  return (
    <Container ref={ref}>
      <Helmet
        title={`Press | Stavros Markonis`}
        meta={[
          {
            name: `description`,
            content: seo.description
          },
          {
            name: 'keywords',
            content: seo.keywords?.map(({text}: {text: string}) => text).join(', '),
          },
          {
            property: `og:title`,
            content: `Press | Stavros Markonis`,
          },
          {
            property: `og:description`,
            content: seo.description
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${bisbikis?.publicURL}`
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: `Press | Stavros Markonis`,
          },
          {
            name: `twitter:description`,
            content: seo.description
          },
        ]}
      />
      <Appear>
        <PressItemsContainer translation={(state as any)?.enableTranslationTransition}>
          {pressEntries.sort(byDateDesc).map(pressEntry => (
            <PressItem
              key={pressEntry?.id}
              item={pressEntry}
            />
          ))}
        </PressItemsContainer>
      </Appear>
    </Container>
  );
}

export default Press;
