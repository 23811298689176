import React from 'react';
import styled from 'styled-components';
import {Strapi_PressEntry} from '../../typegen/graphql';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import RichText from './RichText';
import {devices} from '../styles/breakpoints';
import theme from '../styles/theme';

export type Props = {
  item: Strapi_PressEntry
}

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  color: white;
  @media ${devices.tabletPortrait} {
    gap: 24px;
  }
  @media ${devices.tabletLandscape} {
    flex-direction: row;
  }
`;

const Title = styled.p`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
  margin: 0;
`;

const Description = styled(RichText)`
  margin: 0;
  font-size: 16px;
  margin-top: 8px;
  @media ${devices.desktop} {
    margin-top: 12px;
  }
`;

const SeeMore = styled.a`
  margin-top: 16px;
  width: fit-content;
  font-size: 16px;
  display: flex;
  color: white;
  text-decoration: none;
  transition: all 200ms;
  position: relative;
  &::after {
    content: '';
    pointer-events: none;
    position: absolute;
    transform: scaleX(0.7);
    transform-origin: left;
    width: 50%;
    height: 2px;
    bottom: -1px;
    left: -15%;
    background-color: ${theme.palette.primary};
    opacity: 0.3;
    transition: all 300ms ease-out;
  }
  &:hover {
    color: ${theme.palette.primary}70;
    &::after {
      opacity: 0.5;
      bottom: -1px;
      left: 0;
      transform: scaleX(1);
    }
    /* color: ${theme.palette.secondary}; */

  }
  &:focus {
    outline: none;
    /* color: ${theme.palette.secondary}; */
  }
`;

const Date = styled.p`
  margin: 0;
  font-size: 14px;
  color: #a2a2a2;
`;

const ImageContainer = styled.div`
  width: 100%;
  @media ${devices.tabletLandscape} {
    width: 360px;
  }
  @media ${devices.desktop} {
    width: 480px;
  }
  @media ${devices.desktopL} {
    width: 540px;
  }
  @media ${devices.desktopXL} {
    width: 600px;
  }
`;

const Image = styled(GatsbyImage)`
  /* position: absolute !important; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  /* height: 100%; */
  @media ${devices.tabletLandscape} {
    width: 360px;
    height: 200px;
  }
  @media ${devices.desktop} {
    width: 480px;
    height: 267px;;
  }
  @media ${devices.desktopL} {
    width: 540px;
    height: 304px;
  }
  @media ${devices.desktopXL} {
    width: 600px;
    height: 338px;;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;
  @media ${devices.tabletPortrait} {
    padding: 0px;
  }
  flex: 1;
`;

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const PressItem: React.FC<Props> = ({item}) => {
  const {entry} = item;
  const year = entry.date.substring(0, 4);
  const month = months[parseInt(entry.date.substring(5, 7), 10) - 1];
  const image = getImage((entry?.thumbnail as any).file)
  if (!image) {return null}
  return (
    <Container>
      <Info>
        <Date>{`${month} ${year}`}</Date>
        <Title>{entry?.title}</Title>
        <Description source={entry?.description} />
        <SeeMore
          href={entry?.URL}
          target='_blank'
          rel="noopener noreferrer"
        >
          See more...
        </SeeMore>
      </Info>
      <ImageContainer>
        <Image image={image} alt="Image of press item"/>
      </ImageContainer>
    </Container>
  );
}

export default PressItem;

