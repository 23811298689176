import {graphql, useStaticQuery} from 'gatsby';
import {Strapi_ComponentMoleculesSeo, Strapi_PressEntry} from '../../typegen/graphql';

const usePressData = () => {
  const data = useStaticQuery(graphql`
  query {
    strapi {
      pressEntries {
        ...PressEntry
      }
      press {
        seo {
          ...Seo
        }
      }
    }
  }
`);
  return ({
    pressEntries: data.strapi.pressEntries as Strapi_PressEntry[],
    seo: data.strapi.press.seo as Strapi_ComponentMoleculesSeo
  });
};

export default usePressData;
